<template>
    <router-view></router-view>
</template>

<script>
export default {
    data: function() {
        return {};
    }
}
</script>